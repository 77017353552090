  <template>
    <section>
      <div class="w-full bg-white mt-4">
        <div class="w-full border-b p-4">
          <div class="grid grid-cols-1 lg:grid-cols-3">
            <div class="w-full text-center lg:text-left">
              <div class="buttonBack">
                <Button
                  @click="$router.back()"
                  style="border: transparent"
                  class="mr-4 mb-2 buttonBack font-medium bg-gray-600"
                  label="Volver"
                  icon="pi pi-angle-left"
                />
              </div>
            </div>
            <div class="flex items-center justify-center text-gray-600 font-bold">
              <i class="pi pi-user-plus mr-2 font-bold"></i>
              <p v-if="$route.params.id" class="text-xl">
                Editar empleado
              </p>
              <p v-else class="text-xl">
                Nuevo empleado
              </p>
            </div>
            <div class="w-full text-center lg:text-right mt-2 lg:mt-0">
              <Button @click="crearEmpleado" icon="pi pi-save" class="mr-2 px-4" label="Guardar" />
            </div>
          </div>
        </div>
        <div class="flex justify-center lg:mt-10">
          <div class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative">
            <img
              alt="Midone Tailwind HTML Admin Template"
              class="rounded-full border-blue-600 border-2"
              src='../../../../images/user.jpg'
            />
            <button
              v-if="!perfil.urlPath"
              @click="displayMaximizableImage = true"
              class="absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-blue-600 rounded-full p-2"
            >
              <i class="pi pi-pencil w-4 h-4 text-white"></i>
            </button>
          </div>
        </div>
        <div v-if="Object.keys(errors).length" class="w-full flex items-center">
          <p class="font-medium mx-4">Por favor completa los siguientes campos para continuar:</p>
          <div v-for="(error, i) in Object.keys(errors)" :key="i">
            <p class="m-2 px-2 py-1 bg-red-300 text-red-800 font-bold rounded-md">
              {{ error }}
            </p>
          </div>
        </div>
        <div  v-if="perfil.urlPath" class="w-full flex justify-center mt-4">
          <div class="px-2 py-1 rounded-md bg-blue-300 text-blue-800">
            {{ perfil.fileName }}
            <button @click="perfil = {}">
              <i class="pi pi-times text-xs ml-2"></i>
            </button>
          </div>
        </div>
        <div class="w-full flex my-4 py-4">
          <div class="w-3/12 px-4">
            <div v-for="(tipo, i) in tiposInformacion" :key="i">
              <div class="p-3 my-2 border rounded-md w-full" :class="tipo === tipoSeleccionado ? 'border-blue-600' : ''">
                <button @click="tipoSeleccionado = tipo" :class="tipo === tipoSeleccionado ? 'text-blue-600 font-bold' : ''">
                  {{ tipo.name }}
                </button>
              </div>
            </div>
          </div>
          <div class="w-9/12 ml-4 border-l px-6">
            <!-- Informacion personal -->
            <div v-if="tipoSeleccionado.id === 1">
              <div class="w-full">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div class="field grid w-full my-2">
                    <label class="text-gray-600" for="nombre">Nombre:</label>
                    <InputText @keydown="nameKeydown($event)" id="nombre" autocomplete="off" v-model="model.nombre" class="w-full mb-2" type="text" placeholder="" />
                    <MessageError :text="errors.nombre"/>
                  </div>
                  <div class="field grid w-full my-2">
                    <label class="text-gray-600" for="apellido">Apellido:</label>
                    <InputText @keydown="nameKeydown($event)" id="apellido" v-model="model.apellido" class="w-full mb-2" type="text" placeholder="" />
                    <MessageError :text="errors.apellido"/>
                  </div>
                  <div class="field grid w-full">
                    <label class="text-gray-600" for="numeroDocumento">Número de documento</label>
                    <div class="flex gap-2">
                      <div class="w-12">
                        <Dropdown
                          v-model="model.tipoDocumento"
                          :options="tiposDocumento"
                          optionValue="id"
                          class="w-full mb-2 border-1 border-gray-300 rounded-md"
                          id="tipoDocumento"
                          placeholder="">
                          <template #value="slotProps">
                            <div class="p-dropdown-car-value text-center" v-if="slotProps.value">
                              <span>{{slotProps.value}}</span>
                            </div>
                            <span style="margin-top: 0.2rem; margin-bottom: 0.2rem;" class="text-center w-full flex justify-between items-center" v-else>
                              <p>
                                {{slotProps.placeholder}}
                              </p>
                              <i class="pi pi-angle-down"></i>
                            </span>
                          </template>
                          <template #option="slotProps">
                            <div class="p-dropdown-car-option">
                              <span>{{slotProps.option.name}}</span>
                            </div>
                          </template>
                        </Dropdown>
                        <MessageError :text="errors.tipoDocumento"/>
                      </div>
                      <div class="w-full">
                        <InputText @keyup="encontrarDocumentoPersona" v-model="model.numeroDocumento" id="numeroDocumento" class="w-full sm:mb-2 mb-0" type="text" placeholder="" />
                        <MessageError :text="errors.numeroDocumento"/>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label class="text-gray-600" for="estadoCivil">Estado civil:</label>
                    <Dropdown
                      v-model="model.estadoCivil"
                      :options="estadosCiviles"
                      id="estadoCivil"
                      class="w-full border-1 border-gray-300 rounded-md"
                      placeholder="">
                      <template #value="slotProps">
                        <div class="p-dropdown-car-value" v-if="slotProps.value">
                          <span>{{slotProps.value}}</span>
                        </div>
                        <span style="margin-top: 0.2rem; margin-bottom: 0.2rem;" class="text-center w-full flex justify-between items-center" v-else>
                          <p>
                            {{slotProps.placeholder}}
                          </p>
                          <i class="pi pi-angle-down"></i>
                        </span>
                      </template>
                      <template #option="slotProps">
                        <div class="p-dropdown-car-option">
                          <span>{{slotProps.option}}</span>
                        </div>
                      </template>
                    </Dropdown>
                    <MessageError :text="errors.estadoCivil"/>
                  </div>
                  <div>
                    <label class="text-gray-600" for="genero">Genero:</label>
                    <Dropdown
                      v-model="model.genero"
                      :options="generos"
                      id="genero"
                      class="w-full border-1 border-gray-300 rounded-md"
                      placeholder="">
                      <template #value="slotProps">
                        <div class="p-dropdown-car-value" v-if="slotProps.value">
                          <span>{{slotProps.value}}</span>
                        </div>
                        <span style="margin-top: 0.2rem; margin-bottom: 0.2rem;" class="text-center w-full flex justify-between items-center" v-else>
                          <p>
                            {{slotProps.placeholder}}
                          </p>
                          <i class="pi pi-angle-down"></i>
                        </span>
                      </template>
                      <template #option="slotProps">
                        <div class="p-dropdown-car-option">
                          <span>{{slotProps.option}}</span>
                        </div>
                      </template>
                    </Dropdown>
                    <MessageError :text="errors.genero"/>
                  </div>
                  <div class="field grid w-full">
                    <label class="text-gray-600" for="dob">Fecha de nacimiento:</label>
                    <input :max="dayjs().format('YYYY-MM-DD')" :class="model.dob ? '' : 'text-white'" placeholder="Date" class="p-button-secondary mb-2 w-full p-2 border rounded-md" id="dob" type="date" v-model="model.dob">
                    <MessageError :text="errors.dob"/>
                  </div>
                  <div class="field grid w-full">
                    <label class="text-gray-600" for="email">Email:</label>
                    <InputText id="email" v-model="model.email" class="w-full mb-2" type="email" placeholder="" />
                    <MessageError :text="errors.email"/>
                  </div>
                  <div class="field grid w-full">
                    <label class="text-gray-600" for="telefono">Telefono:</label>
                    <InputMask id="telefono" mask="(999) 999-9999" v-model="model.telefono" class="w-full sm:mb-2 mb-0" type="text" placeholder="" />
                    <!-- <InputText id="telefono" v-model="model.telefono" class="w-full mb-2" type="text" placeholder="" /> -->
                    <MessageError :text="errors.telefono"/>
                  </div>
                  <div class="field grid w-full">
                    <label class="text-gray-600" for="localizacion">Localización:</label>
                    <Dropdown
                      v-model="model.localizacion"
                      :options="localizaciones"
                      :filter="true"
                      class="w-full mb-2 border-1 border-gray-300 rounded-md"
                      optionValue="id"
                      optionLabel="name"
                    >
                      <template #value="slotProps">
                        <span v-if="!slotProps.value" style="margin-top: 0.2rem; margin-bottom: 0.2rem;" class="text-center w-full flex justify-between items-center">
                          <p>
                            {{slotProps.placeholder}}
                          </p>
                          <i class="pi pi-angle-down"></i>
                        </span>
                      </template>
                    </Dropdown>
                    <MessageError :text="errors.localizacion"/>
                  </div>
                  <div class="field grid w-full">
                    <label class="text-gray-600" for="direccion">Dirección:</label>
                    <InputText id="direccion" v-model="model.direccion" class="w-full mb-2" type="text" placeholder="" />
                    <MessageError :text="errors.direccion"/>
                  </div>
                  <div class="field w-full">
                    <label class="text-gray-600" for="direccion">Tiene hijos:</label>
                    <Dropdown
                      v-model="model.hijos"
                      :options="siNo"
                      class="w-full mb-2 border-1 border-gray-300 rounded-md"
                      optionValue="value"
                      optionLabel="id"
                    ></Dropdown>
                    <MessageError :text="errors.hijos"/>
                  </div>
                  <div v-if="model.hijos" class="field w-full">
                    <label class="text-gray-600" for="direccion">Cantidad de hijos:</label>
                    <InputText id="direccion" v-model="model.direccion" class="w-full mb-2" type="text" placeholder="" />
                    <MessageError :text="errors.direccion"/>
                  </div>
                </div>
                <div class="w-full py-2 my-2 flex">
                  <i class="pi pi-users text-sm text-blue-800 mr-2"></i>
                  <p class="font-medium text-blue-800">Contacto de emergencia</p>
                </div>
                <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div class="field grid w-full">
                    <label class="text-gray-600" for="nombre_contacto_emergencia">Nombre:</label>
                    <InputText id="nombre_contacto_emergencia" v-model="model.nombre_contacto_emergencia" class="w-full mb-2" type="text" placeholder="" />
                    <MessageError :text="errors.nombre_contacto_emergencia"/>
                  </div>
                  <div class="field grid w-full">
                    <label class="text-gray-600" for="telefono_contacto_emergencia">Telefono:</label>
                    <InputMask id="telefono_contacto_emergencia" mask="(999) 999-9999" v-model="model.telefono_contacto_emergencia" class="w-full sm:mb-2 mb-0" type="text" placeholder="" />
                    <MessageError :text="errors.telefono_contacto_emergencia"/>
                  </div>
                  <div class="field grid w-full">
                    <label class="text-gray-600" for="direccion_contacto_emergencia">Dirección:</label>
                    <InputText id="direccion_contacto_emergencia" v-model="model.direccion_contacto_emergencia" class="w-full mb-2" type="text" placeholder="" />
                    <MessageError :text="errors.direccion_contacto_emergencia"/>
                  </div>
                  <div class="field grid w-full">
                    <label class="text-gray-600" for="parentesco_contacto_emergencia">Parentesco:</label>
                    <InputText id="parentesco_contacto_emergencia" v-model="model.parentesco_contacto_emergencia" class="w-full mb-2" type="text" placeholder="" />
                    <MessageError :text="errors.parentesco_contacto_emergencia"/>
                  </div>
                </div>
              </div>
            </div>
            <!-- Soportes -->
            <div v-if="tipoSeleccionado.id === 3">
              <div v-if="documentosRequeridosCargo.length">
                <div v-for="(documento, j) in documentosRequeridosCargo" :key="j">
                  <div class="w-full p-2 flex justify-between items-center border-b">
                    <p class="font-medium">
                      {{ capitalize(documento.infoDoc.nombre) }}
                    </p>
                    <i v-if="documento.documento.urlPath" class="pi pi-check-circle text-green-600 font-medium mx-2"></i>
                    <button v-else @click="abrirModalRecibirSoporte(documento)" class="rounded-full bg-blue-600 flex items-center justify-center w-8 h-8">
                      <i class="pi pi-cloud-upload text-white"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="w-full p-2 rounded-md bg-green-300 text-green-800 mt-4" v-else>
                <p>Por favor selecciona un cargo, para poder subir los documentos correspondientes. Si ya tienes un cargo seleccionado revisa si el cargo que elejiste tiene documentos asignados</p>
              </div>
            </div>
            <!-- Informacion del cargo -->
            <div v-if="tipoSeleccionado.id === 4">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div class="field grid w-full">
                  <label class="text-gray-600" for="cargo">Cargo:</label>
                  <Dropdown
                    v-model="model.cargo"
                    :options="cargos"
                    optionValue="id"
                    optionLabel="name"
                    @change="obtenerDocumentosCargo(model.cargo)"
                    :filter="true"
                    class="w-full border-1 border-gray-300 rounded-md mb-2"
                    placeholder="">
                    <template #value="slotProps">
                      <span v-if="!slotProps.value" class="text-center w-full flex justify-between items-center">
                        <p>
                          {{slotProps.placeholder}}
                        </p>
                        <i class="pi pi-angle-down"></i>
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="p-dropdown-car-option">
                        <span>{{slotProps.option.name}}</span>
                      </div>
                    </template>
                  </Dropdown>
                  <MessageError :text="errors.cargo"/>
                </div>
                <div class="field grid w-full">
                  <label class="text-gray-600" for="salario">Salario:</label>
                  <InputNumber disabled id="salario" v-model="salarioCargo" class="w-full mb-2" type="text" placeholder="" />
                  <MessageError :text="errors.salario"/>
                </div>
                <!-- FALTA CONFIGURAR -->
                <div class="field grid w-full">
                  <label class="text-gray-600" for="jefeInmediato">Jefe Inmediato:</label>
                  <Dropdown
                    v-model="model.jefeInmediato"
                    :options="jefes"
                    optionValue="id"
                    optionLabel="name"
                    :filter="true"
                    class="w-full border-1 border-gray-300 rounded-md"
                    placeholder="">
                    <template #value="slotProps">
                      <span v-if="!slotProps.value" class="text-center w-full flex justify-between items-center">
                        <p>
                          {{slotProps.placeholder}}
                        </p>
                        <i class="pi pi-angle-down"></i>
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="p-dropdown-car-option">
                        <span>{{slotProps.option.name}}</span>
                      </div>
                    </template>
                  </Dropdown>
                  <MessageError :text="errors.jefeInmediato"/>
                </div>
                <div class="field grid w-full">
                  <label class="text-gray-600" for="sede">Sede:</label>
                  <Dropdown
                    v-model="model.sede"
                    :options="sedes"
                    id="sede"
                    optionValue="id"
                    optionLabel="name"
                    :filter="true"
                    class="w-full border-1 border-gray-300 rounded-md"
                    placeholder="">
                    <template #value="slotProps">
                      <span v-if="!slotProps.value" class="text-center w-full flex justify-between items-center">
                        <p>
                          {{slotProps.placeholder}}
                        </p>
                        <i class="pi pi-angle-down"></i>
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="p-dropdown-car-option">
                        <span>{{slotProps.option.name}}</span>
                      </div>
                    </template>
                  </Dropdown>
                  <MessageError :text="errors.sede"/>
                </div>
                <div class="field grid w-full">
                  <label class="text-gray-600" for="area">Área:</label>
                  <Dropdown
                    disabled
                    v-model="model.area"
                    :options="areas.filter(a => a.parent_area_id === null)"
                    optionValue="id"
                    optionLabel="name"
                    class="w-full border-1 border-gray-300 rounded-md">
                    <template #value="mm" >
                      <span v-if="!mm.value" class="text-center w-full flex justify-between items-center">
                        <p>
                          {{mm.placeholder}}
                        </p>
                        <i class="pi pi-angle-down"></i>
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="p-dropdown-car-option">
                        <span>{{slotProps.option.name}}</span>
                      </div>
                    </template>
                  </Dropdown>
                  <MessageError :text="errors.area"/>
                </div>
                <div class="field grid w-full">
                  <label class="text-gray-600" for="area">Sub area:</label>
                  <Dropdown
                    disabled
                    v-model="model.subArea"
                    :options="subAreas"
                    optionValue="id"
                    optionLabel="name"
                    class="w-full border-1 border-gray-300 rounded-md">
                    <template #value="mm" >
                      <span v-if="!mm.value" class="text-center w-full flex justify-between items-center">
                        <p>
                          {{mm.placeholder}}
                        </p>
                        <i class="pi pi-angle-down"></i>
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="p-dropdown-car-option">
                        <span>{{slotProps.option.name_parent}}</span>
                      </div>
                    </template>
                  </Dropdown>
                  <MessageError :text="errors.area"/>
                </div>
              </div>
            </div>
            <!-- Informacion del empleado -->
            <div v-if="tipoSeleccionado.id === 5">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label class="text-gray-600" for="contrato">Empresa:</label>
                  <Dropdown
                    v-model="model.empresa"
                    id="contrato"
                    :options="empresas"
                    optionValue="id"
                    optionLabel="name"
                    class="w-full border-1 border-gray-300 rounded-md"
                    placeholder="">
                    <template #value="slotProps">
                      <span v-if="!slotProps.value" style="margin-top: 0.2rem; margin-bottom: 0.2rem;" class="text-center w-full flex justify-between items-center">
                        <p>
                          {{slotProps.placeholder}}
                        </p>
                        <i class="pi pi-angle-down"></i>
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="p-dropdown-car-option">
                        <span>{{slotProps.option.name}}</span>
                      </div>
                    </template>
                  </Dropdown>
                  <MessageError :text="errors.contrato"/>
                </div>
                <div>
                  <label class="text-gray-600" for="contrato">Tipo contratación:</label>
                  <Dropdown
                    v-model="model.contrato"
                    id="contrato"
                    :options="contratos"
                    optionValue="id"
                    optionLabel="name"
                    class="w-full border-1 border-gray-300 rounded-md"
                    placeholder="">
                    <template #value="slotProps">
                      <span v-if="!slotProps.value" style="margin-top: 0.2rem; margin-bottom: 0.2rem;" class="text-center w-full flex justify-between items-center">
                        <p>
                          {{slotProps.placeholder}}
                        </p>
                        <i class="pi pi-angle-down"></i>
                      </span>
                    </template>
                    <template #option="slotProps">
                      <div class="p-dropdown-car-option">
                        <span>{{slotProps.option.name}}</span>
                      </div>
                    </template>
                  </Dropdown>
                  <MessageError :text="errors.contrato"/>
                </div>
                <div class="field grid w-full">
                  <label class="text-gray-600" for="eps">Eps:</label>
                  <AutoComplete id="eps" inputClass="w-full" v-model="model.eps" :suggestions="eps" @complete="buscarSeguridadSocial($event, 1)" optionLabel="name" class="w-full" />
                  <div class="text-blue-600 flex items-center text-xs mt-1">
                    <button @click="openModalSeguridadSocial(1)" class="mr-2">Administrar</button>
                    <i class="pi pi-external-link text-xs"></i>
                  </div>
                  <MessageError :text="errors.eps"/>
                </div>
                <div class="field grid w-full">
                  <label class="text-gray-600" for="afp">Afp:</label>
                  <AutoComplete inputClass="w-full" id="afp" v-model="model.afp" :suggestions="afp" @complete="buscarSeguridadSocial($event, 2)" optionLabel="name" class="w-full" />
                  <div class="text-blue-600 flex items-center text-xs mt-1">
                    <button @click="openModalSeguridadSocial(2)" class="mr-2">Administrar</button>
                    <i class="pi pi-external-link text-xs"></i>
                  </div>
                  <MessageError :text="errors.afp"/>
                </div>
                <div class="field grid w-full mt-2">
                  <label class="text-gray-600" for="ccf">Ccf:</label>
                  <AutoComplete inputClass="w-full" id="ccf" v-model="model.ccf" :suggestions="ccf" @complete="buscarSeguridadSocial($event, 3)" optionLabel="name" class="w-full" />
                  <div class="text-blue-600 flex items-center text-xs mt-1">
                    <button @click="openModalSeguridadSocial(3)" class="mr-2">Administrar</button>
                    <i class="pi pi-external-link text-xs"></i>
                  </div>
                  <MessageError :text="errors.ccf"/>
                </div>
                <div class="field grid w-full">
                  <label class="text-gray-600 mb-0" for="ccf">Reloj biometrico:</label>
                  <InputNumber class="mb-4" inputId="integeronly" v-model="model.reloj_biometrico" />
                  <MessageError :text="errors.ccf"/>
                </div>
                <div class="field grid w-full">
                  <label class="text-gray-600" for="telefono_coorporativo">Telefono coorporativo:</label>
                  <InputMask id="telefono_coorporativo" mask="(999) 999-9999" v-model="model.telefono_coorporativo" class="w-full sm:mb-2 mb-0" type="text" placeholder="" />
                  <MessageError :text="errors.telefono_coorporativo"/>
                </div>
                <div class="field grid w-full">
                  <label class="text-gray-600" for="email_coorporativo">Email coorporativo:</label>
                  <InputText id="email_coorporativo" v-model="model.email_coorporativo" class="w-full mb-2" type="email" placeholder="" />
                  <MessageError :text="errors.email_coorporativo"/>
                </div>
              </div>
            </div>
            <!-- Historial de contratos -->
            <div v-if="tipoSeleccionado.id === 6">
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div class="field grid w-full">
                  <label class="text-gray-600" for="nombreReferencia">Numero de contrato:</label>
                  <InputText id="nombreReferencia" v-model="model.contrato" class="w-full mb-2" type="text" placeholder="" />
                </div>
                <div class="field grid w-full">
                  <label class="text-gray-600" for="fechaInicio">Fecha inicio:</label>
                  <input placeholder="Date" class="p-button-secondary mb-2 w-full p-2 border rounded-md" :class="model.fechaInicio ? '' : 'text-white'" id="fechaInicio" type="date" v-model="model.fechaInicio">
                  <MessageError :text="errors.fechaInicio"/>
                </div>
                <div class="field grid w-full">
                  <label class="text-gray-600" for="fechaFin">Fecha fin:</label>
                  <input placeholder="Date" class="p-button-secondary mb-2 w-full p-2 border rounded-md placeholderStyle" :class="model.fechaFin ? '' : 'text-white'" id="fechaFin" type="date" v-model="model.fechaFin">
                  <MessageError :text="errors.fechaFin"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog header="Agregar soporte" v-model:visible="displayUploadFile" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
        <div>
          <div class="my-2">
            <div class="mt-2">
              <FilePond ref="pond"/>
            </div>
          </div>
        </div>
        <template class="text-center" #footer>
          <Button label="Guardar" class="w-full" @click="guardarSoporte()" autofocus />
        </template>
      </Dialog>
      <Dialog header="Listado de eps" v-model:visible="modalSeguridadSocial" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :closable="false" :style="{width: '30vw'}" :modal="true">
          <template #header>
            <div class="flex justify-between items-center w-full">
              <div>
                <h3>Listado de eps</h3>
              </div>
              <Button icon="pi pi-times" @click="cerrarModalSeguridadSocial()" class="p-button-rounded p-button-text" />
            </div>
          </template>
          <div v-for="(registro, i) in arrayModalSeguridadSocial" :key="i">
            <div class="flex my-2">
              <div class="w-6/12">
                <div v-if="registro.editar">
                  <InputText type="text" v-model="registro.name" />
                </div>
                <div v-else>
                  {{ registro.name }}
                </div>
              </div>
              <div class="w-6/12 flex justify-end">
                <Button v-if="registro.editar" icon="pi pi-save" @click="editarSeguridadSocial(registro)" class="p-button-rounded" />
                <Button v-else icon="pi pi-pencil" @click="registro.editar = true" class="p-button-rounded p-button-success" />
              </div>
            </div>
          </div>
      </Dialog>
      <Dialog header="Agregar imagen" v-model:visible="displayMaximizableImage" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
        <div>
          <div class="my-2">
            <div class="mt-2">
              <FilePondImage ref="pond"/>
            </div>
          </div>
        </div>
        <template class="text-center" #footer>
          <Button label="Guardar" class="w-full" @click="closeMaximizableImage" autofocus />
        </template>
      </Dialog>
    </section>
  </template>
  <script>
  import AreasServiceNew from '../../../../services_back_new/areas.service'
  import EmpleadosServiceNew from '../../../../services_back_new/empleados.service'
  import UsuariosService from '../../../../services/usuarios.service'
  import LocalizacionService from '../../../../services/localizacion.service'
  import CargosService from '../../../../services/cargos.service'
  import CargosServiceNew from '../../../../services_back_new/cargos.service'
  import EmpleadosService from '../../../../services/empleados.service'
  import SedesService from '../../../../services/sedes.service'
  import AreasService from '../../../../services/areas.service'
  import FilePondStore from '../../../../stores/filepond.store'
  import FilePondImage from './filepondImage.vue'
  import ContratosService from '../../../../services/contratos.service'
  import FilePond from './filepond.vue'
  import Swal from 'sweetalert2'
  import AutoComplete from 'primevue/autocomplete'
  import * as yup from 'yup'
  import { onMounted, ref, computed } from 'vue'
  import { useRouter } from 'vue-router'
  import { useField, useForm } from 'vee-validate'
  import dayjs from 'dayjs'
  import { capitalize } from 'lodash'
  export default {
    name: 'CrearEmpleados',
    components: {
      AutoComplete,
      FilePond,
      FilePondImage
    },
    setup () {
      // Services
      const _UsuariosService = ref(new UsuariosService())
      const _LocalizacionService = ref(new LocalizacionService())
      const _CargosService = ref(new CargosService())
      const _EmpleadosService = ref(new EmpleadosService())
      const _SedesService = ref(new SedesService())
      const _AreasService = ref(new AreasService())
      const _ContratosService = ref(new ContratosService())
      const _CargosServiceNew = ref(new CargosServiceNew())
      const _AreasServiceNew = ref(new AreasServiceNew())
      const _EmpleadosServiceNew = ref(new EmpleadosServiceNew())
      const router = useRouter()
      // Computed
      const adjunto = computed(() => FilePondStore.getters.adjunto)
      const image = computed(() => FilePondStore.getters.imagen)
      // References
      const tiposInformacion = ref([
        {
          name: 'Datos personales',
          id: 1
        },
        {
          name: 'Información del cargo',
          id: 4
        },
        {
          name: 'Información del empleado',
          id: 5
        },
        {
          name: 'Soportes',
          id: 3
        },
        {
          name: 'Historial de contratos',
          id: 6
        },
        {
          name: 'Reporte de novedades',
          id: 7
        },
        {
          name: 'Evaluación de desempeño',
          id: 8
        }
      ])
      const estadosCiviles = ref(['Soltero', 'Casado', 'Divorciado', 'Viudo', 'Concubinato'])
      const generos = ref(['Femenino', 'Masculino'])
      const tiposDocumento = ref([])
      const cargos = ref([])
      const jefes = ref([])
      const prueba = ref()
      const sedes = ref([])
      const areas = ref([])
      const subAreas = ref([])
      const contratos = ref([])
      const perfil = ref({})
      const modalSeguridadSocial = ref(false)
      const eps = ref([])
      const afp = ref([])
      const ccf = ref([])
      const arrayModalSeguridadSocial = ref([])
      const seguridadesListado = ref([])
      const salarioCargo = ref(0)
      const pond = ref()
      const documentosRequeridosCargo = ref([])
      const displayUploadFile = ref(false)
      const displayMaximizableImage = ref(false)
      const empresas = ref([
        {
          name: 'Pharmasan',
          id: 1
        },
        {
          name: 'Daily-Tech',
          id: 2
        }
      ])
      const siNo = ref([
        {
          id: 'Sí',
          value: true
        },
        {
          id: 'No',
          value: false
        }
      ])
      const jefesFiltrados = ref([])
      const localizaciones = ref([])
      // const soportesEnviar = ref([])
      const personaId = ref(0)
      const documentoSeleccionado = ref({})
      const tipoSeleccionado = ref(tiposInformacion.value[0])
      const schema = yup.object({
        nombre: yup.string().required('Este campo es requerido').label(''),
        apellido: yup.string().required('Este campo es requerido').label(''),
        tipoDocumento: yup.string().required('Este campo es requerido').label(''),
        numeroDocumento: yup.string().required('Este campo es requerido').label(''),
        estadoCivil: yup.string(),
        genero: yup.string().required('Este campo es requerido').label(''),
        dob: yup.date().label(''),
        email: yup.string().email().label(''),
        email_coorporativo: yup.string().email().label(''),
        telefono: yup.string(),
        telefono_coorporativo: yup.string(),
        localizacion: yup.number().label(''),
        reloj_biometrico: yup.number().label(''),
        direccion: yup.string().label(''),
        hijos: yup.bool().label(''),
        hijosCantidad: yup.number().label(''),
        contrato: yup.number().label(''),
        fechaInicio: yup.date().label(''),
        fechaFin: yup.date().label(''),
        eps: yup.string().label(''),
        afp: yup.string().label(''),
        ccf: yup.string().label(''),
        cargo: yup.string().label(''),
        nombre_contacto_emergencia: yup.string().label(''),
        telefono_contacto_emergencia: yup.string().label(''),
        direccion_contacto_emergencia: yup.string().label(''),
        parentesco_contacto_emergencia: yup.string().label(''),
        empresa: yup.number(),
        jefeInmediato: yup.string().label(''),
        sede: yup.string().label('').required('Este campo es requerido')
      })
      const { errors, values: model, handleSubmit, handleReset } = useForm({
        validationSchema: schema
      })
      useField('nombre')
      useField('nombre_contacto_emergencia')
      useField('telefono_contacto_emergencia')
      useField('direccion_contacto_emergencia')
      useField('parentesco_contacto_emergencia')
      useField('apellido')
      useField('tipoDocumento', null, { initialValue: 'CC' })
      useField('numeroDocumento')
      useField('estadoCivil')
      useField('genero')
      useField('dob')
      useField('email')
      useField('email_coorporativo')
      useField('telefono')
      useField('telefono_coorporativo')
      useField('localizacion')
      useField('direccion')
      useField('hijos')
      useField('hijosCantidad', null, { initialValue: 0 })
      useField('contrato')
      useField('fechaInicio')
      useField('fechaFin')
      useField('eps')
      useField('reloj_biometrico')
      useField('afp')
      useField('ccf')
      useField('cargo')
      useField('jefeInmediato')
      useField('sede')
      useField('empresa')
      // Methods
      const obtenerTiposDocumento = () => {
        _UsuariosService.value.typeDocument().then(({ data }) => {
          tiposDocumento.value = data
        })
      }
      const obtenerLocalizaciones = () => {
      _LocalizacionService.value.paginate().then(({ data }) => {
        data.map(a => {
          localizaciones.value.push({
            id: a.id,
            municipio: a.nomMpio,
            departamento: a.nomDpto,
            name: a.id + ' - ' + a.nomDpto + ' - ' + a.nomMpio
          })
        })
      })
      }
      const obtenerCargos = () => {
        _CargosService.value.paginate().then(({ data }) => {
          cargos.value = data
        })
      }
      const obtenerSedes = () => {
        _SedesService.value.paginate().then(({ data }) => {
          sedes.value = data
        })
      }
      const obtenerJefes = () => {
        _EmpleadosService.value.getAll().then(({ data }) => {
          for (const i of data) {
            jefes.value.push({
              id: i.id,
              name: i.persona.full_name,
              documento: i.persona.number_document,
              first_name: i.persona.firt_name,
              last_name: i.persona.last_name,
              email: i.persona.email,
              telefono: i.persona.telefono,
              localizacion: i.persona.localizacion,
              direccion: i.persona.direccion,
              type_document_id: i.persona.type_document_id
            })
          }
        })
      }
      const obtenerAreas = () => {
        _AreasService.value.paginate().then(({ data }) => {
          areas.value = data
          subAreas.value = []
          for (const i of data) {
            if (i.parent_area_id !== null) {
              subAreas.value.push({
                ...i,
                name_parent: data.find(a => a.id === i.parent_area_id).name + ' - ' + i.name
              })
            }
          }
        })
      }
      const obtenerSubAreas = (areaId) => {
        subAreas.value = areas.value.filter(a => a.parent_area_id === areaId)
      }
      const obtenerTiposContrato = () => {
        _ContratosService.value.paginate().then(({ data }) => {
          contratos.value = data
        })
      }
      const obtenerDocumentosCargo = (id) => {
        _CargosServiceNew.value.find({ id }).then(({ data }) => {
          salarioCargo.value = data.salario ? parseInt(data.salario) : 0
          if (data.area_id) {
            model.area = areas.value.find(a => a.id === data.area_id).parent_area_id
            model.subArea = data.area_id
          }
          for (const i of data.documentosRelacionados) {
            documentosRequeridosCargo.value.push({
              ...i,
              documento: {}
            })
          }
        })
      }
      const obtenerArea = (id) => {
        _AreasServiceNew.value.find({ id }).then(({ data }) => {
          console.log('este es el area--->', data)
        })
      }
      const abrirModalRecibirSoporte = (documento) => {
        documentoSeleccionado.value = documento
        displayUploadFile.value = true
      }
      const guardarSoporte = () => {
        pond.value.onSubmitSupport().then(() => {
          Swal.fire(
            'Soporte guardado!',
            'Tu soporte se adjunto con exito!',
            'success'
          ).then(() => {
            displayUploadFile.value = false
            documentosRequeridosCargo.value.find(a => a.id === documentoSeleccionado.value.id).documento = adjunto.value
          })
        })
      }
      const listarSeguridadSocial = (seguridades) => {
        _EmpleadosService.value.getSocialSecurity().then(({ data }) => {
          seguridadesListado.value = data
          if (seguridades) {
            for (const i of data) {
              if (i.id === parseInt(seguridades.eps) && i.type === 1) {
                model.eps = i.name
              }
              if (i.id === parseInt(seguridades.afp) && i.type === 2) {
                model.afp = i.name
              }
              if (i.id === parseInt(seguridades.ccf) && i.type === 3) {
                model.ccf = i.name
              }
            }
          }
        })
      }
      const buscarSeguridadSocial = ({ query }, tipo) => {
        eps.value = []
        afp.value = []
        ccf.value = []
        if (seguridadesListado.value.length) {
          for (const i of seguridadesListado.value) {
            if (i.name) {
              if (tipo === 1) {
                if (i.name.includes(query) && tipo === i.type) {
                  eps.value.push(i.name)
                }
              }
              if (tipo === 2) {
                if (i.name.includes(query) && tipo === i.type) {
                  afp.value.push(i.name)
                }
              }
              if (tipo === 3) {
                if (i.name.includes(query) && tipo === i.type) {
                  ccf.value.push(i.name)
                }
              }
            }
          }
        }
      }
      const openModalSeguridadSocial = (tipo) => {
        if (seguridadesListado.value.length) {
          for (const i of seguridadesListado.value) {
            if (i.type === tipo) {
              arrayModalSeguridadSocial.value.push({
                ...i,
                editar: false
              })
            }
          }
        }
        modalSeguridadSocial.value = true
      }
      const editarSeguridadSocial = (info) => {
        Swal.fire({
          title: 'Advertencia',
          text: 'Si cambia este registro, todos los usuarios que lo contengan seran afectados',
          showDenyButton: true,
          confirmButtonText: 'Cambiar',
          denyButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
            const object = {
              id: info.id,
              name: info.name,
              type: info.type
            }
            _EmpleadosService.value.putSocialSecurity(object).then(({ data }) => {
              Swal.fire('Guardado!', '', 'success').then(() => {
                cerrarModalSeguridadSocial()
              })
            })
          } else if (result.isDenied) {
            Swal.fire('No guardado', '', 'info')
          }
        })
        info.editar = false
      }
      const cerrarModalSeguridadSocial = () => {
        arrayModalSeguridadSocial.value = []
        listarSeguridadSocial()
        modalSeguridadSocial.value = false
      }
      const encontrarDocumentoPersona = () => {
        if (jefes.value.some(a => a.documento === model.numeroDocumento)) {
          Swal.fire({
                title: 'Persona encontrada',
                html:
                  'El empleado que estas creando ya cuenta con un usuario, deseas Auto completar la informacion?',
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText:
                  '<i class="fa fa-thumbs-up"></i> Si',
                confirmButtonAriaLabel: 'Thumbs up, great!',
                cancelButtonText:
                  '<i class="fa fa-thumbs-down"></i> No',
                cancelButtonAriaLabel: 'Thumbs down'
              }).then((result) => {
                if (result.isConfirmed) {
                  jefes.value.map(a => {
                    if (a.documento === model.numeroDocumento) {
                      model.nombre = a.first_name
                      model.apellido = a.last_name
                      model.tipoDocumento = a.type_document_id
                      model.email = a.email ? a.email : ''
                      model.telefono = a.telefono ? a.telefono : ''
                      model.direccion = a.direccion ? a.direccion : ''
                      model.localizacion = a.localizacion ? a.localizacion : 0
                      personaId.value = a.id
                    }
                  })
                  console.log('LLENAR LA INFORMACION')
                }
              })
        }
      }
      const completarJefes = ({ query }) => {
        jefesFiltrados.value = []
        for (const i of jefes.value) {
          if (i.name.toLowerCase().startsWith(query.toLowerCase())) {
            jefesFiltrados.value.push(i)
          }
        }
      }
      const nameKeydown = (e) => {
        if (/[^A-z\s]/.test(e.key)) {
          e.preventDefault()
        }
      }
      const closeMaximizableImage = () => {
        pond.value.onSubmitSupport().then(() => {
          perfil.value = {
            urlPath: image.value.urlPath,
            fileName: 'Perfil'
          }
          displayMaximizableImage.value = false
        })
      }
      const crearEmpleado = handleSubmit(async (values) => {
        values.perfil = perfil.value
        values.soportes = []
        values.soportes.push(...documentosRequeridosCargo.value)
        console.log('asi van docs--->', documentosRequeridosCargo.value)
        _EmpleadosServiceNew.value.post(values).then(() => {
          Swal.fire({
            title: 'Guardado',
            text: 'Empleado creado conéxito',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then(() => {
            router.push({ name: 'pharmasan.recursos-humanos.empleados' })
            handleReset()
          })
        })
      })
      onMounted(() => {
        obtenerTiposDocumento()
        obtenerLocalizaciones()
        obtenerCargos()
        obtenerJefes()
        obtenerSedes()
        obtenerAreas()
        obtenerTiposContrato()
        listarSeguridadSocial()
      })
      return {
        tiposInformacion,
        tipoSeleccionado,
        nameKeydown,
        errors,
        model,
        tiposDocumento,
        estadosCiviles,
        generos,
        dayjs,
        localizaciones,
        cargos,
        jefes,
        completarJefes,
        jefesFiltrados,
        prueba,
        sedes,
        areas,
        subAreas,
        obtenerSubAreas,
        contratos,
        encontrarDocumentoPersona,
        empresas,
        eps,
        afp,
        ccf,
        cerrarModalSeguridadSocial,
        editarSeguridadSocial,
        openModalSeguridadSocial,
        modalSeguridadSocial,
        buscarSeguridadSocial,
        obtenerDocumentosCargo,
        documentosRequeridosCargo,
        capitalize,
        displayUploadFile,
        abrirModalRecibirSoporte,
        pond,
        guardarSoporte,
        adjunto,
        documentoSeleccionado,
        siNo,
        salarioCargo,
        obtenerArea,
        arrayModalSeguridadSocial,
        crearEmpleado,
        closeMaximizableImage,
        displayMaximizableImage,
        perfil
      }
    }
  }
  </script>

  <style lang="scss" scoped>
  :global(.swal2-container) {z-index: 1000000 !important}
  ::v-deep(.p-dropdown-trigger) {
    display: none;
  }
  </style>
