import { createStore } from 'vuex'

const storeFilePondImage = {
  state: {
    _adjunto: {},
    _imagen: {}
  },
  getters: {
    adjunto: state => state._adjunto,
    imagen: state => state._imagen
  },
  mutations: {
    setAdjunto (state, payload) {
      state._adjunto = {
        ...payload
      }
    },
    setImagen (state, payload) {
      state._imagen = {
        ...payload
      }
    }
  },
  actions: {
    uploadFile ({ commit }, payload) {
      commit('setAdjunto', payload)
    },
    uploadImage ({ commit }, payload) {
      commit('setImagen', payload)
    }
  }
}
const store = createStore(storeFilePondImage)
export default {
  namespaced: true,
  ...store
}
