<template>
    <Toast />
    <div>
      <file-pond
        style="min-height: 120px; background-color: #f1f0ef; border-radius: 5px;"
        name="test"
        ref="pond"
        label-idle="Drop files here..."
        credits="false"
        maxFileSize="100MB"
        labelMaxFileSizeExceeded="El archivo es demasiado grande"
        labelMaxFileSize="El tamaño máximo del archivo es de 100MB"
        :v-bind:allow-multiple="multipleFiles"
        v-bind:allowProcess="false"
        accepted-file-types="application/pdf,text/plain"
        :server="{ process }"
        instantUpload="false"
        v-on:init="handleFilePondInit"
        @initfile="handleFilePontInitFile"
        @addfile="handleFilePondAddFile"
        data-pdf-preview-height="640"
        pdfPreviewHeight="640"
        pdfComponentExtraParams="toolbar=1&navpanes=0&scrollbar=0&view=fitV&zoom=100"
        labelTapToCancel="Toca para cancelar"
        labelButtonAbortItemLoad = "Cancelar descarga"
        labelButtonRemoveItem = "Borrar archivo"
        labelButtonRetryItemLoad="Cargando"
        labelButtonRetryItemProcessing ="Reintentar"
        labelButtonUndoItemProcessing ="Deshacer"
        labelDecimalSeparator =","
        labelFileAdded = "Archivo Añadido"
        labelFileCountPlural ="Archivos"
        labelFileCountSingular ="Archivo"
        labelFileLoadError ="Error al subir"
        labelFileLoading ="Cargando"
        labelFileProcessing ="Subiendo"
        labelFileProcessingAborted ="Subida cancelada"
        labelFileProcessingComplete ="Subida completa"
        labelFileProcessingError ="Error al subir archivo"
        labelFileRemoved="Archivo eliminado"
        labelFileSizeNotAvailable ="Tamaño no disponible"
        labelFileWaitingForSize ="Comprobando tamaño"
        labelIdle ='<div class="text-center h-100 font-medium" style="text-align: -webkit-center; margin-top: 35px"><svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg> Arrastra <br /> ó <br /> <p class="text-blue-600">Selecciona aqui tus archivos<p /><div />'
        labelTapToRetry="Toca para reintentar"
        labelTapToUndo="Toca para deshacer"
        labelThousandsSeparator=""
        stylePanelLayout=""
      />
    </div>

  </template>

  <script>
  import { ref } from 'vue'
  import vueFilePond from 'vue-filepond'
  import 'filepond/dist/filepond.min.css'
  import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
  import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
  import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
  import FilePondPluginPdfPreview from 'filepond-plugin-pdf-preview'
  import FilePondPluginValidateSize from 'filepond-plugin-file-validate-size'
  import { helper } from '@/utils/helper'
  import filepondStore from '../../../../stores/filepond.store'

  // Create component
  const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    FilePondPluginPdfPreview,
    FilePondPluginValidateSize
  )

  export default {
    name: 'filePondUpload',
    emits: ['captureFile', 'removeFile'],
    props: {
      multipleFiles: {
        type: Boolean,
        default: false
      }
    },
    components: {
      FilePond
    },
    setup (props, context) {
      const pond = ref()

      const handleFilePondInit = () => {
        console.log('FilePond se ha iniciado correctamente')
      }

      const handleFilePontInitFile = () => {
      }
      const handleFilePondAddFile = (error, file) => {
        if (!error) {
          context.emit('captureFile', file)
        }
      }

      /**/
      const process = async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
        await filepondStore.dispatch('uploadFile', {
          urlPath: await helper.base64String(file)
        })
        load({
          urlPath: await helper.base64String(file)
        })
      }

      const onSubmitSupport = async () => {
        return pond.value.processFiles().then(() => {
          pond.value.removeFiles()
        })
      }

      const onRemoveSupport = async () => {
        return pond.value.removeFiles()
      }

      return {
        handleFilePondInit,
        handleFilePontInitFile,
        process,
        pond,
        onSubmitSupport,
        onRemoveSupport,
        handleFilePondAddFile
      }
    }

  }
  </script>

  <style scoped>
   .filepond--root {
      max-height: 20em;
  }
  </style>
